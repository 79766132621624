import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  Box,
} from "@mui/material";
import { useExperiences } from "../context/ExperiencesContext";
import { useAuth } from "../context/AuthContext";
import ExperiencesAPI from "../services/ExperiencesAPI";
import moment from "moment";
import Experience from "../components/dashboard/Experience";
import { usePageMetadata } from "../context/PageMetadataContext";

// Import the new dropdown components
import SystemOptionsDropdown from "../components/SystemOptionsDropdown";
import GuideOptionsDropdown from "../components/GuideOptionsDropdown";

import "./Experiences.scss";

const Experiences = () => {
  const { experiences, fetchData } = useExperiences();
  const { organizationId, userId } = useAuth();
  const { setTitle, setShowTitleInHeader } = usePageMetadata();

  // State for dialog open/close
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // State for the new experience form
  const [newExperience, setNewExperience] = useState({
    name: `Class ${experiences && experiences.length + 1}, ${moment().format(
      "MMM Do YYYY"
    )}`,
    organizationId: organizationId || "",
    userId: userId || "",
    systemId: "",
    guideId: "",
  });

  useEffect(() => {
    setShowTitleInHeader(true);
    setTitle("Experiences");
  }, [setShowTitleInHeader, setTitle]);

  // Reset the new experience form when the experiences list or auth data changes
  useEffect(() => {
    setNewExperience({
      name: `Class ${experiences && experiences.length + 1}, ${moment().format(
        "MMM Do YYYY"
      )}`,
      organizationId: organizationId || "",
      userId: userId || "",
      systemId: "",
      guideId: "",
    });
  }, [experiences, organizationId, userId]);

  // Handle text input change (experience name)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewExperience((prev) => ({ ...prev, [name]: value }));
  };

  // Handle system selection change from SystemOptionsDropdown
  const handleSystemChange = (selectedSystemId) => {
    setNewExperience((prev) => ({ ...prev, systemId: selectedSystemId }));
  };

  // Handle guide selection change from GuideOptionsDropdown
  const handleGuideChange = (selectedGuideId) => {
    setNewExperience((prev) => ({ ...prev, guideId: selectedGuideId }));
  };

  const handleCreateExperience = async () => {
    try {
      await ExperiencesAPI.post(
        "experiences",
        {
          ...newExperience,
          experienceId: crypto.randomUUID(),
        },
        {
          requiresAuth: true,
        }
      );
      fetchData();
      setIsDialogOpen(false);
      // Reset the form after creation
      setNewExperience({
        name: "",
        organizationId: organizationId || "",
        userId: userId || "",
        systemId: "",
        guideId: "",
      });
    } catch (error) {
      console.error("Error creating experience:", error);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Experiences
        </Typography>

        {/* Render the experiences as a vertical list */}
        <List>
          {experiences?.map((experience) => (
            <ListItem key={experience.experienceId} divider>
              <Experience experience={experience} />
            </ListItem>
          ))}
        </List>

        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsDialogOpen(true)}
          >
            Launch New Experience
          </Button>
        </Box>
      </Box>

      {/* Dialog for creating a new experience */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Launch New Experience</DialogTitle>
        <DialogContent>
          <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
            {/* System dropdown */}
            <SystemOptionsDropdown
              value={newExperience.systemId}
              onChange={handleSystemChange}
            />

            {/* Guide dropdown */}
            <GuideOptionsDropdown
              value={newExperience.guideId}
              onChange={handleGuideChange}
      
            />

            {/* Experience Name Input */}
            <TextField
              fullWidth
              margin="normal"
              label="Experience Name"
              name="name"
              variant="outlined"
              value={newExperience.name}
              onChange={handleInputChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCreateExperience}
            variant="contained"
            color="primary"
          >
            Create
          </Button>
          <Button
            onClick={() => setIsDialogOpen(false)}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Experiences;
