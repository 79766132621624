// GuideEditorPage.js
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useExperiences } from "../context/ExperiencesContext";
import GuideEditor from "../components/GuideEditor";
import { Box, CircularProgress } from "@mui/material";
import ExperiencesApi from "../services/ExperiencesAPI";


// Helper to generate a new UUID
const generateId = () => crypto.randomUUID();

const GuideEditorPage = () => {
  const { guideId } = useParams();
  const [searchParams] = useSearchParams();
  const { guides } = useExperiences();
  const [guide, setGuide] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   
    if (guideId === "new") {
      const cloneFrom = searchParams.get("cloneFrom");
      if (cloneFrom) {
        ExperiencesApi.get(`guides/${cloneFrom}`, { requiresAuth: true })
          .then((response) => {
            const guideData = response.data;
            // Clone and update all insightDatas:
            const clonedInsights = cloneInsightData(
              guideData.insightDatas || []
            );
            // Create the new guide object with a new id and a modified name.
            setGuide({
              ...guideData,
              id: generateId(), // new guide id
              name: guideData.name + " (Clone)",
              insightDatas: clonedInsights,
              insightDataIds: clonedInsights.map((insight) => insight.id),
            });
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching guide for clone", error);
            setLoading(false);
          });
      } else {
        setGuide({
          name: "",
          id: generateId(),
          insightDatas: [],
          insightDataIds: [],
        });
        setLoading(false);
      }
    } else {
      // guideId was extracted from the sk, so use it directly to query the full guide JSON
      ExperiencesApi.get(`guides/${guideId}`, { requiresAuth: true })
        .then((response) => {
          setGuide(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching guide", error);
          setLoading(false);
        });
    }
  }, [guideId, guides, searchParams]);

  useEffect(() => {
    console.log(guide)
  }, [guide])


  if (loading || !guide) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <GuideEditor initialGuide={guide} />
    </Box>
  );
};


const cloneInsightData = (insightDatas) => {
  // Create a mapping of oldId -> newId
  const idMapping = {};
  // First pass: generate new ids for each insight
  const clonedInsights = insightDatas.map((insight) => {
    const newId = generateId();
    idMapping[insight.id] = newId;
    // Return a shallow clone with the new id (we'll update nested references next)
    return {
      ...insight,
      id: newId,
    };
  });

  // Second pass: update any nested references in triggers and conditions
  const updatedInsights = clonedInsights.map((insight) => {
    // Update triggers if the trigger type indicates a reference to another insight.
    // (Assuming trigger type 2, for example, represents "InsightComplete")
    let updatedTriggers = insight.insightTriggers;
    if (Array.isArray(updatedTriggers)) {
      updatedTriggers = updatedTriggers.map((trigger) => {
        if (trigger.insightTriggerType === 2 && idMapping[trigger.triggerId]) {
          return {
            ...trigger,
            triggerId: idMapping[trigger.triggerId],
          };
        }
        return trigger;
      });
    }

    // Update start conditions
    let updatedStartConditions = insight.startConditions;
    if (Array.isArray(updatedStartConditions)) {
      updatedStartConditions = updatedStartConditions.map((condition) => {
        // Assuming condition.conditions is an array of IDs that might reference an insight.
        const newConditions = Array.isArray(condition.conditions)
          ? condition.conditions.map((cond) =>
              idMapping[cond] ? idMapping[cond] : cond
            )
          : condition.conditions;
        return {
          ...condition,
          conditions: newConditions,
        };
      });
    }

    // Update end conditions similarly
    let updatedEndConditions = insight.endConditions;
    if (Array.isArray(updatedEndConditions)) {
      updatedEndConditions = updatedEndConditions.map((condition) => {
        const newConditions = Array.isArray(condition.conditions)
          ? condition.conditions.map((cond) =>
              idMapping[cond] ? idMapping[cond] : cond
            )
          : condition.conditions;
        return {
          ...condition,
          conditions: newConditions,
        };
      });
    }

    return {
      ...insight,
      insightTriggers: updatedTriggers,
      startConditions: updatedStartConditions,
      endConditions: updatedEndConditions,
    };
  });

  return updatedInsights;
};


export default GuideEditorPage;
