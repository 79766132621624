import React, { useState } from "react";
import { Alert, Stack } from "@mui/material";
import AuthForm from "../../components/AuthForm"; // Reusable form component
import { signIn, confirmSignIn } from "aws-amplify/auth"; // Import only the necessary functions
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import Page from "../../components/Page";
import backgroundImage from "../../images/Sign-In-Background.jpg";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [challengeSession, setChallengeSession] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleSignIn = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await signIn({
        username: email,
        password,
        options: {
          authFlowType: "USER_PASSWORD_AUTH", // Explicit auth flow
        },
      });

      if (
        response?.nextStep?.signInStep ===
        "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        setIsNewPasswordRequired(true);
        setChallengeSession(response); // Save session for completing the flow
      } else {
        console.log("User signed in successfully:", response);
        navigate(from); // Redirect after successful login
      }
    } catch (err) {
      console.error("Error during sign-in:", err);
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetNewPassword = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await confirmSignIn({
        challengeResponse: newPassword,
      });
      console.log("Password updated successfully:", response);
      navigate(from); // Redirect after successful password update
    } catch (err) {
      console.error("Error setting new password:", err);
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page>
      <Box display="flex" height="100vh">
        {/* Left Side: Information Section */}
        <Box
          flex={1}
          bgcolor="#f5f5f5"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding={4}
          textAlign="center"
          sx={{
            position: "relative", // Enable pseudo-element positioning
            backgroundImage: `url(${backgroundImage})`, // Use the imported image
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
              zIndex: 1,
            },
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              position: "relative",
              zIndex: 2,
              backgroundColor: "white",
              borderRadius: "20px",
              padding: "80px 60px",
              maxWidth: "600px",
              width: "100%",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              textAlign: "center",
            }}
          >
            <Stack spacing={4}>
              <Typography variant="h1" component="h1" gutterBottom>
                Welcome back to 10k Science!
              </Typography>
              <Typography variant="body1" gutterBottom>
                Access your account and continue your journey.
              </Typography>
            </Stack>
            <Typography variant="body2" color="textSecondary">
              If you’re new here, <a href="/signup">sign up</a> and get started
              for free.
            </Typography>
          </Box>
        </Box>

        {/* Right Side: Login Form */}
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding={4}
          // width="100%"
          // maxWidth={800}
        >
          {error && (
            <Alert
              severity="error"
              sx={{ marginBottom: 2, width: "100%", maxWidth: 400 }}
            >
              {error}
            </Alert>
          )}
          {!isNewPasswordRequired ? (
            <AuthForm
              title="Sign In"
              fields={[
                {
                  label: "Email",
                  type: "text",
                  value: email,
                  onChange: (e) => setEmail(e.target.value),
                },
                {
                  label: "Password",
                  type: "password",
                  value: password,
                  onChange: (e) => setPassword(e.target.value),
                },
              ]}
              onSubmit={handleSignIn}
              isLoading={isLoading}
              footerAction={
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => navigate("/reset-password")} // Navigate to Reset Password page
                >
                  Forgot Password?
                </Button>
              }
            />
          ) : (
            <AuthForm
              title="Set New Password"
              fields={[
                {
                  label: "New Password",
                  type: "password",
                  value: newPassword,
                  onChange: (e) => setNewPassword(e.target.value),
                },
              ]}
              onSubmit={handleSetNewPassword}
              isLoading={isLoading}
            />
          )}
        </Box>
      </Box>
    </Page>
  );
};

export default LoginPage;
