
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Authenticator } from "@aws-amplify/ui-react";
import { AuthProvider } from "./context/AuthContext";
import { AnalyticsProvider } from "./context/AnalyticsContext";
import { PageMetadataProvider } from "./context/PageMetadataContext";
import { ExperiencesProvider } from "./context/ExperiencesContext";
import { ContentProvider } from './context/ContentContext';
import { CssBaseline, ThemeProvider } from "@mui/material";

import theme from "./themes/theme";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AnalyticsProvider>
        <PageMetadataProvider>
          <ExperiencesProvider>
            <ContentProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
              </ThemeProvider>
            </ContentProvider>
          </ExperiencesProvider>
        </PageMetadataProvider>
      </AnalyticsProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
