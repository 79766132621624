import React, { createContext, useContext, useState, useCallback } from "react";
import API from "../services/API";

export const ContentContext = createContext(null);

/**
 * Provider component that holds and manages content items (systems, environments, elements) in state.
 */
export const ContentProvider = ({ children }) => {
  // contentState will store items in an object keyed by "contentType-itemId".
  // e.g., { "systems-123": {...}, "environments-456": {...}, ... }
  const [contentState, setContentState] = useState({});

  // Helper to build a unique key from contentType and itemId
  const makeKey = (contentType, itemId) => `${contentType}-${itemId}`;

  /**
   * Fetch an item from the API if it isn't already in our state.
   * Returns a Promise that resolves to the item data.
   * - If it's already in state, returns that data immediately.
   */
  const getItem = useCallback(
    async (contentType, itemId) => {
      const key = makeKey(contentType, itemId);
      const cachedItem = contentState[key];

      // 1) If item already exists in context state, return it immediately.
      if (cachedItem) {
        return cachedItem;
      }

      // 2) Otherwise, fetch it from the API.
      const response = await API.get(`${contentType}/${itemId}`);
      const data = response.data;

      // 3) Store in context.
      setContentState((prev) => ({
        ...prev,
        [key]: data,
      }));

      // 4) Return the fetched item
      return data;
    },
    [contentState]
  );

  /**
   * Helper to form a thumbnail URL based on the item id.
   */
  const getThumbnail = useCallback((id) => {
    return `http://s3-us-west-2.amazonaws.com/10k-assets/thumbnails/${id}.png`;
  }, []);

  // Provide these functions via context.
  const contextValue = {
    getItem,
    getThumbnail,
  };

  return (
    <ContentContext.Provider value={contextValue}>
      {children}
    </ContentContext.Provider>
  );
};

/**
 * Custom hook for accessing our content context.
 */
export const useContent = () => useContext(ContentContext);
