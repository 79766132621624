import React, { useEffect, useState } from 'react';
import "./Dashboard.scss";


import API from '../services/APIDeprecated'
import { useAuth } from '../context/AuthContext';
import Filters from '../components/dashboard/Filters';
import AnalyticsHeader from '../components/AnalyticsHeader';
import { AnalyticsProvider, useAnalytics } from '../context/AnalyticsContext';
import Insight from '../components/dashboard/Insight';
import Header from '../components/header/Header';
import UserAccountButton from '../components/header/UserAccountButton';
import { usePageMetadata } from '../context/PageMetadataContext';
import GridHeader from '../components/dashboard/GridHeader';
import moment from 'moment';
import Statistics from '../components/dashboard/DashboardStatistics';
import SortToggleButton from '../components/SortToggleButton';
import { useNavigate } from "react-router-dom";

const Dashboard = ({ }) => {
const navigate = useNavigate();

  const { insights, sortInsights, activeInsightSort, statistics } = useAnalytics();
  const { setTitle, setShowTitleInHeader } = usePageMetadata();

  useEffect(() => {
    setShowTitleInHeader(true);
    setTitle("Dashboard");
  }, [])

  useEffect(() => {
    
   
  }, [statistics])

  


  return (
    <div className="dashboard">
      <div className="content">
        {statistics && <h3>Overview</h3>}
        {statistics && <Statistics />}
        {!statistics && (
          <center>
            <br />
            <br />
            <br />
            <h1>Launch a new Experience to begin</h1>
            <button onClick={() => navigate("/experiences")}>
              Go to Experiences
            </button>
            <br />
            <br />
          </center>
        )}
        {insights && insights.length > 0 && <h3>Insights</h3>}
        {insights && insights.length > 0 && (
          <GridHeader>
            <div className="grid-column medium">Environment</div>
            <div className="grid-column wide">Question</div>
            <div className="grid-column medium">
              <SortToggleButton
                attribute="responseCount"
                sortFunction={sortInsights}
                activeSort={activeInsightSort}
              />
              Completed
            </div>
            <div className="grid-column narrow">
              <SortToggleButton
                attribute="meanScore"
                sortFunction={sortInsights}
                activeSort={activeInsightSort}
              />
              Score
            </div>
          </GridHeader>
        )}
        {insights &&
          insights.map((insight, index) => (
            <Insight key={insight.insightId} insight={insight} />
          ))}
      </div>
      <Filters />
    </div>
  );
}

export default Dashboard;
