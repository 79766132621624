import "./index.scss";

import Main from "./pages/Main";
import Session from "./pages/Session";
import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import Pricing from "./pages/Pricing";
import Confirmation from "./pages/Confirmation";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/authentication/Login";
import { AuthProvider, useAuth } from './context/AuthContext';
import ResetPasswordPage from "./pages/authentication/ResetPasswordPage";
import ChangePassword from "./pages/authentication/ChangePassword";
import SignUp from "./pages/authentication/SignUp";

import './services/amplify-config'; 
import LoginPage from "./pages/authentication/LoginPage";
import Page from "./components/Page";
import { AnalyticsProvider } from "./context/AnalyticsContext";
import Contact from "./pages/Contact";
import Sessions from "./pages/Sessions";
import Demo from "./pages/Demo";
import Experiences from "./pages/Experiences";
import AccountPage from "./pages/authentication/Account";

import EditGuidePage from "./pages/GuideEditorPage";
import GuideEditorPage from "./pages/GuideEditorPage";
import GuidesListPage from "./pages/GuidesListPage";


const ProtectedRoute = ({redirectPath = '/login' }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  if(isLoading)
  return(
  <div>Loading....</div>);

  return isAuthenticated ? <Outlet /> : <Navigate to={redirectPath} state={{ from: location }} replace />;
};

const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Dashboard />} /> */}
        <Route path="public/sessions/:sessionId" element={<Session />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/confirmation" element={<Confirmation />} />

        <Route path="/login" element={<LoginPage />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/iste" element={<Demo />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />
        <Route element={<Page pricing={false} code={false} />}>
          <Route path="/" element={<ProtectedRoute />}>
            <Route path="/guides" element={<GuidesListPage />} />
            <Route path="/guides/:guideId" element={<GuideEditorPage />} />
            <Route index element={<Dashboard />} />
            <Route path="sessions" exact element={<Sessions />} />
            <Route path="account" element={<AccountPage />} />

            <Route path="experiences" exact element={<Experiences />} />
            <Route
              path="sessions/:sessionId"
              element={<Session dashboard={true} />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
