// InsightEditor.js
import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Button,
  FormControl,
  InputLabel,
  Collapse,
  IconButton,
  FormControlLabel,
  Checkbox,
  Divider,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";

// Styled IconButton that rotates when expanded
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

// Enum options based on your C# enums

// For InsightType (C# enum: Instruction, Information, Assessment)
const insightTypeOptions = [
  { value: 0, label: "Instruction" },
  { value: 1, label: "Information" },
  { value: 2, label: "Assessment" },
];

// For InsightTriggerType (C# enum: Auto, EnvironmentVisited, InsightComplete, ConditionTriggered, MenuLoaded)
const triggerTypeOptions = [
  { value: 0, label: "Auto" },
  { value: 1, label: "EnvironmentVisited" },
  { value: 2, label: "InsightComplete" },
  { value: 3, label: "ConditionTriggered" },
  { value: 4, label: "MenuLoaded" },
];

// For InsightConditionType (C# enum)
const conditionTypeOptions = [
  { value: 0, label: "EnvironmentsVisited" },
  { value: 1, label: "EnvironmentsNotVisited" },
  { value: 2, label: "ElementsExpanded" },
  { value: 3, label: "ElementsNotExpanded" },
  { value: 4, label: "InsightsCompleted" },
  { value: 5, label: "InsightsNotCompleted" },
  { value: 6, label: "ConditionsTriggered" },
  { value: 7, label: "ConditionsNotTriggered" },
  { value: 8, label: "PlayerPrefsHasKey" },
  { value: 9, label: "PlayerPrefsNotHasKey" },
  { value: 10, label: "TimeSpent" },
];



const InsightEditor = ({ insight, index, onChange, onDelete, allInsights, environments, elements }) => {

  const [expanded, setExpanded] = useState(false);
  const [style, setStyle] = useState({});  


  useEffect(() => {
    if(hasTrigger(1)){
        setStyle({ backgroundColor: "#EAF6F5" });
    }
    else if(hasTrigger(2)){
      setStyle({marginLeft:"40px"})
    }
      

  }, [insight])



  const hasTrigger = ( triggerType ) => {
    return insight.insightTriggers.some(
      (trigger) =>
        trigger.insightTriggerType === triggerType 
    );
  }

  

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  


  // A helper that updates the field in the insight object
  const handleFieldChange = (field, value) => {
    console.log(`FIELD ${field}, VALUE: ${value}`)
    onChange(index, { ...insight, [field]: value });
  };

  // ---------------------------
  // Trigger handlers
  // ---------------------------
  const handleTriggerChange = (triggerIndex, field, value) => {
    console.log(`FIELD: ${field}, VALUE: ${value}`)

    const newTriggers = insight.insightTriggers
      ? [...insight.insightTriggers]
      : [];
    newTriggers[triggerIndex] = {
      ...newTriggers[triggerIndex],
      [field]: value,
    };
    handleFieldChange("insightTriggers", newTriggers);
  };

  const addTrigger = () => {
    const newTriggers = insight.insightTriggers
      ? [...insight.insightTriggers]
      : [];
    newTriggers.push({ name: "", insightTriggerType: 0, triggerId: "" });
    handleFieldChange("insightTriggers", newTriggers);
  };

  const removeTrigger = (triggerIndex) => {
    const newTriggers = insight.insightTriggers
      ? [...insight.insightTriggers]
      : [];
    newTriggers.splice(triggerIndex, 1);
    handleFieldChange("insightTriggers", newTriggers);
  };

  // ---------------------------
  // Start Conditions handlers
  // ---------------------------
  const handleStartConditionChange = (condIndex, field, value) => {
    const newConds = insight.startConditions
      ? [...insight.startConditions]
      : [];
    newConds[condIndex] = {
      ...newConds[condIndex],
      [field]: value,
    };
    handleFieldChange("startConditions", newConds);
  };

  const addStartCondition = () => {
    const newConds = insight.startConditions
      ? [...insight.startConditions]
      : [];
    newConds.push({
      name: "",
      InsightConditionType: 0,
      conditions: "",
    });
    handleFieldChange("startConditions", newConds);
  };

  const removeStartCondition = (condIndex) => {
    const newConds = insight.startConditions
      ? [...insight.startConditions]
      : [];
    newConds.splice(condIndex, 1);
    handleFieldChange("startConditions", newConds);
  };

  // ---------------------------
  // End Conditions handlers
  // ---------------------------
  const handleEndConditionChange = (condIndex, field, value) => {
    const newConds = insight.endConditions ? [...insight.endConditions] : [];
    newConds[condIndex] = {
      ...newConds[condIndex],
      [field]: value,
    };
    handleFieldChange("endConditions", newConds);
  };

  const addEndCondition = () => {
    const newConds = insight.endConditions ? [...insight.endConditions] : [];
    newConds.push({
      name: "",
      InsightConditionType: 0,
      conditions: "",
    });
    handleFieldChange("endConditions", newConds);
  };

  const removeEndCondition = (condIndex) => {
    const newConds = insight.endConditions ? [...insight.endConditions] : [];
    newConds.splice(condIndex, 1);
    handleFieldChange("endConditions", newConds);
  };

  const sxIndent = { ml: 8 };

  return (
  
    <Card sx={style}>
      {/* Card Header: Editable Name field and Expand/Collapse button */}
        {/* {environments.map((env, i) => (
      <p>{env.name}</p>
    ))} */}
      <CardActions
        sx={{
          flexDirection: "column",
          alignItems: "stretch",
          padding: 1,
        }}
      >
        {/* Header Row */}
        {!expanded &&
          hasTrigger(1) &&
          insight.insightTriggers.map((trigger, index) => (
            <Typography variant="caption" sx={{ mt: 0.5, marginBottom: "5px" }}>
              {environments
                .find((e) => e.id === trigger.triggerId)
                ?.name.toUpperCase()}
            </Typography>
          ))}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon fontSize="small" />
          </ExpandMore>
          <TextField
            label="Name"
            value={insight.name || ""}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            sx={{ flexGrow: 1 }}
            size="small"
          />
          <Typography variant="caption" sx={{ ml: 1 }}>
            {insight.id}
          </Typography>
        </Box>

        {/* Insight Text Row */}
        {!expanded && (
          <Typography variant="caption" sx={{ mt: 0.5 }}>
            {insight.text}
          </Typography>
        )}
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={sxIndent}>
          {/* Main Text */}

          <TextField
            label="Script"
            value={insight.text || ""}
            onChange={(e) => handleFieldChange("text", e.target.value)}
            fullWidth
            multiline
            sx={{ marginBottom: 2 }}
          />

          {/* Insight Type Dropdown */}
          <FormControl fullWidth>
            <InputLabel>Insight Type</InputLabel>
            <Select
              label="Insight Type"
              value={
                insight.insightType !== undefined ? insight.insightType : ""
              }
              onChange={(e) =>
                handleFieldChange("insightType", parseInt(e.target.value, 10))
              }
            >
              {insightTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Answer Key */}
          {insight.insightType == 2 && (
            <TextField
              label="Answer Key"
              value={insight.answerKey || ""}
              onChange={(e) => handleFieldChange("answerKey", e.target.value)}
              fullWidth
              multiline
              sx={{ marginBottom: 2 }}
            />
          )}

          {/* Dismissable Checkbox */}
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={insight.dismissable || false}
                onChange={(e) =>
                  handleFieldChange("dismissable", e.target.checked)
                }
              />
            }
            label="Dismissable"
            sx={{ marginBottom: 2 }}
          /> */}

          <Divider sx={{ marginBottom: 2 }} />

          {/* Insight Triggers Section */}
          <Typography variant="h6">Triggers</Typography>
          {insight.insightTriggers &&
            insight.insightTriggers.map((trigger, idx) => (
              <div
                key={idx}
                style={{
                  marginBottom: 16,
                  border: "1px solid #ddd",
                  padding: 8,
                }}
              >
                {trigger.insightTriggerType === 2 && (
                  <p>Previous Insight: {trigger.name}</p>
                )}
                <FormControl fullWidth sx={{ marginBottom: 1 }}>
                  <InputLabel>Trigger Type</InputLabel>
                  <Select
                    label="Trigger Type"
                    value={
                      trigger.insightTriggerType !== undefined
                        ? trigger.insightTriggerType
                        : 0
                    }
                    onChange={(e) => {
                      const newTriggerType = parseInt(e.target.value, 10);
                      const newTriggers = insight.insightTriggers
                        ? [...insight.insightTriggers]
                        : [];
                      newTriggers[idx] = {
                        ...newTriggers[idx],
                        insightTriggerType: newTriggerType,
                        triggerId: "", // Clear triggerId when type changes
                      };
                      handleFieldChange("insightTriggers", newTriggers);
                    }}
                  >
                    {triggerTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Render an environment dropdown if trigger type is EnvironmentVisited */}
                {trigger.insightTriggerType === 1 ? (
                  <FormControl fullWidth size="small">
                    <InputLabel>Environment</InputLabel>
                    <Select
                      label="Environment"
                      value={trigger.triggerId || ""}
                      onChange={(e) => {
                        const selectedId = e.target.value;
                        const selectedEnv = environments.find(
                          (env) => env.id === selectedId
                        );
                        const newTriggers = insight.insightTriggers
                          ? [...insight.insightTriggers]
                          : [];
                        newTriggers[idx] = {
                          ...newTriggers[idx],
                          triggerId: selectedId,
                          name: selectedEnv ? selectedEnv.name : "",
                        };
                        handleFieldChange("insightTriggers", newTriggers);
                      }}
                    >
                      {environments.map((env) => (
                        <MenuItem key={env.id} value={env.id}>
                          {env.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    label="Trigger Id"
                    value={trigger.triggerId || ""}
                    onChange={(e) =>
                      handleTriggerChange(idx, "triggerId", e.target.value)
                    }
                    fullWidth
                    sx={{ marginBottom: 1 }}
                  />
                )}
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => removeTrigger(idx)}
                >
                  Remove Trigger
                </Button>
              </div>
            ))}
          <Button
            variant="outlined"
            onClick={addTrigger}
            sx={{ marginBottom: 2 }}
          >
            Add Trigger
          </Button>

          <Divider sx={{ marginBottom: 2 }} />

          {/* Start Conditions Section */}
          <Typography variant="h6">Start Conditions</Typography>
          {insight.startConditions &&
            insight.startConditions.map((cond, idx) => (
              <div
                key={idx}
                style={{
                  marginBottom: 16,
                  border: "1px solid #ddd",
                  padding: 8,
                }}
              >
                <TextField
                  label="Condition Name"
                  value={cond.name || ""}
                  onChange={(e) =>
                    handleStartConditionChange(idx, "name", e.target.value)
                  }
                  fullWidth
                  sx={{ marginBottom: 1 }}
                />
                <FormControl fullWidth sx={{ marginBottom: 1 }}>
                  <InputLabel>Condition Type</InputLabel>
                  <Select
                    label="Condition Type"
                    value={
                      cond.InsightConditionType !== undefined
                        ? cond.InsightConditionType
                        : ""
                    }
                    onChange={(e) => {
                      const newType = parseInt(e.target.value, 10);
                      const newConds = insight.startConditions
                        ? [...insight.startConditions]
                        : [];
                      newConds[idx] = {
                        ...newConds[idx],
                        InsightConditionType: newType,
                        // Clear the conditions value if using environment dropdown
                        conditions:
                          newType === 0 || newType === 1
                            ? ""
                            : newConds[idx].conditions,
                      };
                      handleFieldChange("startConditions", newConds);
                    }}
                  >
                    {conditionTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {cond.InsightConditionType === 0 ||
                cond.InsightConditionType === 1 ? (
                  <FormControl fullWidth size="small" sx={{ marginBottom: 1 }}>
                    <InputLabel>Environment</InputLabel>
                    <Select
                      label="Environment"
                      value={cond.conditions || ""}
                      onChange={(e) =>
                        handleStartConditionChange(
                          idx,
                          "conditions",
                          e.target.value
                        )
                      }
                    >
                      {environments.map((env) => (
                        <MenuItem key={env.id} value={env.id}>
                          {env.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    label="Conditions (comma separated)"
                    value={cond.conditions || ""}
                    onChange={(e) =>
                      handleStartConditionChange(
                        idx,
                        "conditions",
                        e.target.value
                      )
                    }
                    fullWidth
                    sx={{ marginBottom: 1 }}
                  />
                )}

                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => removeStartCondition(idx)}
                >
                  Remove Start Condition
                </Button>
              </div>
            ))}
          <Button
            variant="outlined"
            onClick={addStartCondition}
            sx={{ marginBottom: 2 }}
          >
            Add Start Condition
          </Button>

          <Divider sx={{ marginBottom: 2 }} />

          {/* End Conditions Section */}
          <Typography variant="h6">End Conditions</Typography>
          {insight.endConditions &&
            insight.endConditions.map((cond, idx) => (
              <div
                key={idx}
                style={{
                  marginBottom: 16,
                  border: "1px solid #ddd",
                  padding: 8,
                }}
              >
                <TextField
                  label="Condition Name"
                  value={cond.name || ""}
                  onChange={(e) =>
                    handleEndConditionChange(idx, "name", e.target.value)
                  }
                  fullWidth
                  sx={{ marginBottom: 1 }}
                />
                <FormControl fullWidth sx={{ marginBottom: 1 }}>
                  <InputLabel>Condition Type</InputLabel>
                  <Select
                    label="Condition Type"
                    value={
                      cond.InsightConditionType !== undefined
                        ? cond.InsightConditionType
                        : ""
                    }
                    onChange={(e) => {
                      const newType = parseInt(e.target.value, 10);
                      const newConds = insight.endConditions
                        ? [...insight.endConditions]
                        : [];
                      newConds[idx] = {
                        ...newConds[idx],
                        InsightConditionType: newType,
                        conditions:
                          newType === 0 || newType === 1
                            ? ""
                            : newConds[idx].conditions,
                      };
                      handleFieldChange("endConditions", newConds);
                    }}
                  >
                    {conditionTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {cond.InsightConditionType === 0 ||
                cond.InsightConditionType === 1 ? (
                  <FormControl fullWidth size="small" sx={{ marginBottom: 1 }}>
                    <InputLabel>Environment</InputLabel>
                    <Select
                      label="Environment"
                      value={cond.conditions || ""}
                      onChange={(e) =>
                        handleEndConditionChange(
                          idx,
                          "conditions",
                          e.target.value
                        )
                      }
                    >
                      {environments.map((env) => (
                        <MenuItem key={env.id} value={env.id}>
                          {env.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    label="Conditions (comma separated)"
                    value={cond.conditions || ""}
                    onChange={(e) =>
                      handleEndConditionChange(
                        idx,
                        "conditions",
                        e.target.value
                      )
                    }
                    fullWidth
                    sx={{ marginBottom: 1 }}
                  />
                )}

                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => removeEndCondition(idx)}
                >
                  Remove End Condition
                </Button>
              </div>
            ))}
          <Button
            variant="outlined"
            onClick={addEndCondition}
            sx={{ marginBottom: 2 }}
          >
            Add End Condition
          </Button>
        </CardContent>

        <CardActions>
          <Button color="error" onClick={() => onDelete(index)}>
            Delete Insight
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};

export default InsightEditor;
