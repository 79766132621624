import { useParams } from "react-router";
import "./Session.scss";
import { useEffect, useState } from "react";
import Analytics from "../services/APIDeprecated";
import ListItem from "../components/ListItem";
import List from "../components/List";
import moment from "moment";
import Event from "../components/dashboard/Event";
import Header from "../components/header/Header";
import TopBar from "../components/TopBar";
import { getItem } from "../services/Content";
import AnalyticsHeader from "../components/AnalyticsHeader";
import ContactLink from "../components/header/ContactLink";
import SessionCodeInput from "../components/header/SessionCodeInput";
import PricingLink from "../components/header/PricingLink";
import { usePageMetadata } from "../context/PageMetadataContext";
import { useAuth } from "../context/AuthContext";
import SessionStatistics from "../components/dashboard/SessionStatistics";

const Session = ({ dashboard }) => {
  const sessionId = useParams().sessionId.toLowerCase();
  const [sessionData, setSessionData] = useState(null);
  const [environmentsVisited, setEnvironmentsVisited] = useState(0);
  const [descriptionsRead, setDescriptionsRead] = useState(0);
  const [insightsCompleted, setInsightsCompleted] = useState(0);
  const [isPolling, setIsPolling] = useState(false);

  const [experience, setExperience] = useState("");
  const { setTitle, setShowTitleInHeader } = usePageMetadata();
  const {isAuthenticated} = useAuth();

    useEffect(() => {
      setShowTitleInHeader(true);
      setTitle("Session");
    }, []);

  useEffect(() => {
    let intervalId;

    const getSessionData = () => {
      Analytics.get(`sessioncode/${sessionId}`).catch(err => console.log(err)).then(response => {
        console.log(response)
        if(!response){
           Analytics.get(`usercode/${sessionId}`).catch(err => console.log(err)).then(res => {
            console.log(res.data)
            res.data && setSessionData(res.data);
        })}
        else{
          response.data && setSessionData(response.data);
        }
       
      })
    }

    getSessionData();
    if (isPolling) {
      // Fetch immediately
      intervalId = setInterval(getSessionData, 3000); // Start polling
      
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clear interval on unmount or when polling stops
      }
    };

  }, [isPolling])

  useEffect(() => {
    sessionData && getStats();
   

    if(isPolling)
    {
      scrollToBottom();
    }
  }, [sessionData])


  useEffect(() => {
    experience && setTitle(`Session - ${experience}`)
  }, [experience])

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };



  const getStats = () => {
    sessionData.length > 0 && sessionData[0].systemId &&
    getItem("systems", sessionData[0].systemId).then(response => setExperience(response.name));

    setEnvironmentsVisited(sessionData.filter(d => d.label == "EnvironmentVisited").length)
    setDescriptionsRead(sessionData.filter(d => d.label == "ElementExpanded").length)
    setInsightsCompleted(sessionData.filter(d => d.pk.includes("Assessment")).length)
  }

  const drawEvents = () => {
    return sessionData.map((s, i) =>
      <ListItem key={i}>
        <h1>{s.label}</h1>

      </ListItem>
    )
  }

  return (
    <div className="session">
      {!dashboard && (
        <Header>
          <PricingLink />
          <ContactLink />
          <SessionCodeInput />
        </Header>
      )}

      <div className="content">
        <TopBar dashboard={dashboard}>
          <div className="row">
            <h2>{environmentsVisited}</h2>
            <h4>Environments visited</h4>
          </div>
          <div className="row">
            <h2>{descriptionsRead}</h2>
            <h4>Descriptions read</h4>
          </div>
          <div className="row">
            <h2>{insightsCompleted}</h2>
            <h4>Insights completed</h4>
          </div>
        </TopBar>

        <AnalyticsHeader
          experience={experience}
          isPolling={isPolling}
          setIsPolling={setIsPolling}
        />

        <SessionStatistics statistics={{environmentsVisited, descriptionsRead, insightsCompleted}}/>

       

        <List>
          {sessionData &&
            sessionData.map((s, i) => <Event dashboard={dashboard} key={s.sk + s.pk} data={s} />)}
        </List>
      </div>
    </div>
  );
}

export default Session;


