import API from "./APIDeprecated";

// import uuid from 'react-uuid';


export const getItem = (contentType, itemId, json) => {
  return API.get(`${contentType}/${itemId}`).then(response => response.data);
}



export const getThumbnail = ( id ) => {
  return `http://s3-us-west-2.amazonaws.com/10k-assets/thumbnails/${id}.png`;
}
