import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

const AccountPage = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut();
      console.log("User logged out successfully.");
      navigate("/login"); // Redirect to login page
    } catch (err) {
      console.error("Error during logout:", err);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="50vh"
      padding={2}
      // bgcolor="#f5f5f5"
    >
      <Box
        width="100%"
        maxWidth={400}
        p={4}
        bgcolor="white"
      
      >
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Account
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Welcome to your account page!
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleLogout}
        >
          Log Out
        </Button>
      </Box>
    </Box>
  );
};

export default AccountPage;
