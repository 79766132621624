import { NavLink } from "react-router-dom";
import "./SideNavigation.scss";

import { ReactComponent as Home } from "../images/Icon_home.svg";

import { ReactComponent as Students } from "../images/Icon_users.svg";

import { ReactComponent as User } from "../images/user_icon.svg"

import { ReactComponent as Guide } from "../images/icon_ai_guide.svg";


import { ReactComponent as Experience } from "../images/icon_experience.svg";



const SideNavigation = ({ }) => {
  return (
    <div className="side-navigation">
      <div className="top">
        <NavLink to="/" className="nav-link" end activeClassName="active">
          <Home />
          Dashboard
        </NavLink>
        <NavLink to="/sessions" className="nav-link" activeClassName="active">
          <Students />
          Student sessions
        </NavLink>
        <NavLink to="/experiences" className="nav-link" activeClassName="active">
          <Experience />
          Experiences
        </NavLink>
        <NavLink to="/guides" className="nav-link" activeClassName="active">
          <Guide />
          Guides <br />(BETA)
        </NavLink>
      </div>

      <div className="bottom">
        <NavLink to="/account" className="nav-link" activeClassName="active">
          <User />
          Account
        </NavLink>
      </div>
    </div>
  );
}

export default SideNavigation;


