import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@mui/material";
import { useExperiences } from "../context/ExperiencesContext";
import { useAuth } from "../context/AuthContext"; // make sure this exports userId
import { useNavigate } from "react-router-dom";

const GuidesListPage = () => {
  const { guides } = useExperiences();
  const auth = useAuth();
  const navigate = useNavigate();

  // Assuming each guide object has a `creator` property
  const currentUserGuides = guides
    ? guides.filter((guide) => guide.creator === auth.userId)
    : [];
  const otherGuides = guides
    ? guides.filter((guide) => guide.creator !== auth.userId)
    : [];

  // Helper function: Extract the actual guide id from the `sk` field.
  const extractGuideId = (guide) => {
    // Assuming sk is in the format "GUIDE#{id}"
    return guide.sk.split("GUIDE#")[1];
  };

  const handleEdit = (guideId) => {
    navigate(`/guides/${guideId}`);
  };

  const handleClone = (guide) => {
    const guideId = extractGuideId(guide);
    navigate(`/guides/new?cloneFrom=${guideId}`);
  };

  const handleCreateNew = () => {
    navigate("/guides/new");
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Button
        variant="contained"
        onClick={handleCreateNew}
        sx={{ marginBottom: 2 }}
      >
        Create New Guide
      </Button>

      {/* Section 1: Current User's Guides */}
      <Typography variant="h5" sx={{ marginBottom: 1 }}>
        My Guides
      </Typography>
      <Grid container spacing={2}>
        {currentUserGuides.length > 0 ? (
          currentUserGuides.map((guide) => {
            const guideId = extractGuideId(guide);
            return (
              <Grid item xs={12} sm={6} md={4} key={guideId}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{guide.name}</Typography>
                    <Typography variant="body2">ID: {guideId}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleEdit(guideId)}>
                      Edit
                    </Button>
                    <Button size="small" onClick={() => handleClone(guide)}>
                      Clone
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Typography>No guides available.</Typography>
        )}
      </Grid>

      {/* Section 2: Other Guides */}
      <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 1 }}>
        All Other Guides
      </Typography>
      <Grid container spacing={2}>
        {otherGuides.length > 0 ? (
          otherGuides.map((guide) => {
            const guideId = extractGuideId(guide);
            return (
              <Grid item xs={12} sm={6} md={4} key={guideId}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{guide.name}</Typography>
                    <Typography variant="body2">ID: {guideId}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      disabled
                      onClick={() => handleEdit(guideId)}
                    >
                      Edit
                    </Button>
                    <Button size="small" onClick={() => handleClone(guide)}>
                      Clone
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Typography>No other guides available.</Typography>
        )}
      </Grid>
    </Box>
  );
};

export default GuidesListPage;
