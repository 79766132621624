import React, { useState } from "react";
import { Alert, Box } from "@mui/material";
import AuthForm from "../../components/AuthForm"; // Reusable form component
import { resetPassword, confirmResetPassword } from "aws-amplify/auth"; // Amplify functions
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const navigate = useNavigate();

  const handleResetRequest = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await resetPassword({username: email});
      console.log("Reset password request sent successfully.");
      setIsCodeSent(true); // Move to the next step
    } catch (err) {
      console.error("Error during reset password request:", err);
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetConfirmation = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await confirmResetPassword({username: email, confirmationCode, newPassword});
      console.log("Password reset successfully.");
      navigate("/login"); // Redirect to login page after successful reset
    } catch (err) {
      console.error("Error during password reset confirmation:", err);
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        padding={20}
      >
        {error && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {error}
          </Alert>
        )}
        {!isCodeSent ? (
          <AuthForm
            title="Reset Password"
            fields={[
              {
                label: "Email",
                type: "text",
                value: email,
                onChange: (e) => setEmail(e.target.value),
              },
            ]}
            onSubmit={handleResetRequest}
            isLoading={isLoading}
            footerAction={
              <Alert severity="info" sx={{ marginTop: 2 }}>
                Enter your email to receive a password reset code.
              </Alert>
            }
          />
        ) : (
          <AuthForm
            title="Confirm Password Reset"
            fields={[
              {
                label: "Verification Code",
                type: "text",
                value: confirmationCode,
                onChange: (e) => setConfirmationCode(e.target.value),
              },
              {
                label: "New Password",
                type: "password",
                value: newPassword,
                onChange: (e) => setNewPassword(e.target.value),
              },
            ]}
            onSubmit={handleResetConfirmation}
            isLoading={isLoading}
            footerAction={
              <Alert severity="info" sx={{ marginTop: 2 }}>
                Check your email for the verification code.
              </Alert>
            }
          />
        )}
      </Box>
    </Page>
  );
};

export default ResetPasswordPage;
