import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID, // Your Cognito User Pool ID
  ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID, // Your Cognito App Client ID (without client secret)
};

const UserPool = new CognitoUserPool(poolData);

export default UserPool;
