import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const SystemOptionsDropdown = ({ value, onChange }) => {
  // Hardcoded system options
  const systemOptions = [
    {
      label: "CRISPR & Sickle Cell",
      systemId: "2985689a-bf21-4e46-8596-f2f59c2b953d",
    },
    {
      label: "Trouble in Sunshine Creek",
      systemId: "ae082427-fe04-49b8-9767-f332025bc23d",
    },
  ];

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="system-options-label">Select System</InputLabel>
      <Select
        labelId="system-options-label"
        value={value}
        label="Select System"
        onChange={(e) => onChange(e.target.value)}
      >
        {systemOptions.map((option) => (
          <MenuItem key={option.systemId} value={option.systemId}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SystemOptionsDropdown;
