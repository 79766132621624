import React, { useEffect, useState } from "react";
import { getItem } from "../../services/Content";
import { Box, Typography, Button } from "@mui/material";
import { useExperiences } from "../../context/ExperiencesContext";

const Experience = ({ experience }) => {
  const [systemLabel, setSystemLabel] = useState("");
  const [copiedId, setCopiedId] = useState(null);

  // Get the guides from the experiences context
  const { guides } = useExperiences();

  const viewerUrl = `https://viewer.10k.science/experiences/${experience.experienceId}`;

  // Fetch system label
  useEffect(() => {
    getItem("systems", experience.systemId, false)
      .then((response) => setSystemLabel(response.name))
      .catch((err) => console.error("Error fetching system label:", err));
  }, [experience.systemId]);

  // Find the guide name from the guides context based on the experience's guideId
  const guideLabel = guides
    ? guides.find((guide) => guide.sk.includes(experience.guideId))?.name
    : "No guide";

  const copyToClipboard = async (url, experienceId) => {
    try {
      await navigator.clipboard.writeText(url);
      setCopiedId(experienceId);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        p: 2,
      }}
    >
      {/* Experience Name */}
      <Box sx={{ width: "20%", textAlign: "left" }}>
        <Typography variant="subtitle1" fontWeight="bold" noWrap>
          {experience.name}
        </Typography>
      </Box>

      {/* System Label */}
      <Box sx={{ width: "20%", textAlign: "center" }}>
        <Typography variant="h6" noWrap>
          {systemLabel}
        </Typography>
      </Box>

      {/* Guide Label */}
      <Box sx={{ width: "20%", textAlign: "center" }}>
        <Typography variant="h6" noWrap>
          {guideLabel}
        </Typography>
      </Box>

      {/* Copy Link Button */}
      <Box sx={{ width: "20%", textAlign: "center" }}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => copyToClipboard(viewerUrl, experience.experienceId)}
        >
          {copiedId === experience.experienceId ? "Copied!" : "Copy link"}
        </Button>
      </Box>

      {/* Join Button */}
      <Box sx={{ width: "20%", textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          href={viewerUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Join
        </Button>
      </Box>
    </Box>
  );
};

export default Experience;
