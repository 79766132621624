import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";

const AuthForm = ({ title, fields, onSubmit, isLoading, footerAction }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    padding={2}
  >
    <Box width="100%" maxWidth={400} p={4} bgcolor="white">
      <Typography variant="h2" component="h1" align="center" gutterBottom>
        {title}
      </Typography>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        noValidate
      >
        {fields.map(({ label, type, value, onChange }, index) => (
          <TextField
            key={index}
            label={label}
            type={type}
            variant="outlined"
            fullWidth
            margin="normal"
            value={value}
            onChange={onChange}
            required
            InputLabelProps={{
              shrink: true, // Force the label to shrink
            }}
          />
        ))}
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </Box>
      {footerAction && (
        <Box textAlign="center" marginTop={2}>
          {footerAction}
        </Box>
      )}
    </Box>
  </Box>
);

export default AuthForm;
