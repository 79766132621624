import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import ExperiencesApi from "../services/ExperiencesAPI";

const ExperiencesContext = createContext();

export const useExperiences = () => useContext(ExperiencesContext);

export const ExperiencesProvider = ({ children }) => {
  const { organizationId } = useAuth();

  const [experiences, setExperiences] = useState(null);
  const [guides, setGuides] = useState(null);
  const [insights, setInsights] = useState(null);
  const [assessments, setAssessments] = useState(null);

  useEffect(() => {
    if (organizationId) {
      fetchData();
    }
    else{
      console.log("No organization found")
    }
  }, [organizationId]);

  const fetchData = () => {
    console.log("Fetching experiences data for organization:", organizationId);

    // Fetch Experiences
    ExperiencesApi.get(`experiences?organization=${organizationId}`, {
      requiresAuth: true,
    })
      .then((response) => {
        console.log("Experiences Response:", response);
        setExperiences(response?.data || []);
      })
      .catch((error) => console.error("Error fetching experiences:", error));

    // Fetch Guides
    ExperiencesApi.get(`guides?organization=${organizationId}`, {
      requiresAuth: true,
    })
      .then((response) => {
        console.log("Guides Response:", response);
        setGuides(response?.data || []);
      })
      .catch((error) => console.error("Error fetching guides:", error));

    // Fetch Insights
    ExperiencesApi.get(`insights?organization=${organizationId}`, {
      requiresAuth: true,
    })
      .then((response) => {
        console.log("Insights Response:", response);
        setInsights(response?.data || []);
      })
      .catch((error) => console.error("Error fetching insights:", error));

    // Fetch Assessments
    ExperiencesApi.get(`assessments?organization=${organizationId}`, {
      requiresAuth: true,
    })
      .then((response) => {
        console.log("Assessments Response:", response);
        setAssessments(response?.data || []);
      })
      .catch((error) => console.error("Error fetching assessments:", error));
  };

  const value = {
    experiences,
    guides,
    insights,
    assessments,
    fetchData
  };

  return (
    <ExperiencesContext.Provider value={value}>
      {children}
    </ExperiencesContext.Provider>
  );
};
