import React, { useState } from "react";
import { Box, Button, TextField, Typography, Alert, List, ListItem, ListItemIcon, ListItemText, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountsAPI from "../../services/AccountsAPI";
import Page from "../../components/Page";
import backgroundImage from "../../images/Sign-Up-Background.jpg";

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    organization: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    const { firstName, lastName, email, organization } = formData;
    if (!firstName || !lastName || !email || !organization) {
      setError("Please fill in all required fields.");
      return;
    }

    try {
      setError("");
      setLoading(true);

      await AccountsAPI.post("/sign-up", formData);

      // Show thank you message
      setIsSubmitted(true);
      
    } catch (err) {
      setError("Something went wrong. Please try again later.");
      console.error(err);
    } finally{
        setLoading(false);
    }
  };

  const features = [
    "Full access to 10k Science Viewer with NGSS-aligned experiences on laptops and desktops",
    "Insights Dashboard with AI-assisted student analytics",
    "Join Codes for your students",
    "AI audio guides for students",
  ];

  return (
    <Page>
      <Box display="flex" height="100vh">
        {/* Left Side: Information Section */}
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          padding={20}
          sx={{
            position: "relative", // Enable pseudo-element positioning
            backgroundImage: `url(${backgroundImage})`, // Use the imported image
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
              zIndex: 1,
            },
            zIndex: 2,
          }}
        >
          {/* Inner Box for content */}
          <Box
            sx={{
              position: "relative", // To ensure it's above the overlay
              zIndex: 2,
              backgroundColor: "white", // Semi-transparent white background
              borderRadius: "20px", // Rounded corners
              padding: "80px 60px", // Add padding
              maxWidth: "800px", // Limit content width
              width: "100%",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Optional shadow for emphasis
              textAlign: "center", // Center-align text
            }}
          >
            <Typography variant="h1" component="h1" gutterBottom>
              See how 10k Science can be used in your classroom
            </Typography>
            <Typography
       
              variant="body1"
              color="textSecondary"
              gutterBottom
            >
              <strong>Your trial includes:</strong>
            </Typography>
            <List>
              {features.map((feature, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon color="success" />
                  </ListItemIcon>
                  <ListItemText primary={feature} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>

        {/* Right Side: Sign-Up Form */}
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding={4}
        >
          <Box width="100%" maxWidth={400} p={4} bgcolor="white">
            {isSubmitted ? (
              <Box textAlign="center">
                <Typography variant="h4" component="h2" gutterBottom>
                  Thank You!
                </Typography>
                <Typography variant="body1">
                  Please check your email to complete your registration.
                </Typography>
              </Box>
            ) : (
              <form onSubmit={handleSubmit} noValidate>
                <Box marginBottom={2}>
                  <Typography
                    variant="h2"
                    component="h1"
                    align="center"
                    gutterBottom
                  >
                    Start your journey with a 7-day free trial.
                  </Typography>
                  <Typography variant="body2" align="center">
                    No credit card required.
                  </Typography>
                </Box>

                {error && (
                  <Alert severity="error" sx={{ marginBottom: 2 }}>
                    {error}
                  </Alert>
                )}

                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="Middle Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleChange}
                />
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="Organization"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="organization"
                  value={formData.organization}
                  onChange={handleChange}
                  required
                />

                <Box display="flex" justifyContent="center" marginTop={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Start Free Trial"
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default SignUp;
