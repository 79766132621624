import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import Cookies from "js-cookie";
import UserPool from "../services/UserPool";
import { Hub } from 'aws-amplify/utils';
import { fetchAuthSession } from 'aws-amplify/auth';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);


export const AuthProvider = ({ children }) => {


  const [authState, setAuthState] = useState({
    jwt: null,
    organizationId: null,
    organizationName: null,
    isAuthenticated: false,
    isLoading: true,
    userId: null,
  });


  useEffect(() => {
    console.log(authState)
  }, [authState])

  useEffect(() => {
    // Listen for authentication changes
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signedIn':
        case 'signedOut':
        case 'tokenRefresh':
          checkCurrentUser(); // Re-fetch session info on relevant auth events
          break;
        default:
          break;
      }
    });

    checkCurrentUser(); // Also check at initial load

    // return () => Hub.remove('auth'); // Clean up listener
  }, []);

  const checkCurrentUser = async () => {

    fetchAuthSession().then(session => {
      console.log(session)
      if (!session.userSub) {
        setAuthState({
          jwt: null,
          organizationId: null,
          isAuthenticated: false,
        });
        return;
      }

      const idToken = session.tokens.idToken;

      console.log("ID token", session.tokens.idToken)
      console.log("ACCESS TOKEN", session.tokens.accessToken)
      const jwt = session.tokens.accessToken.toString();
      const organizationId = idToken.payload["custom:organizationId"];
       const userId = idToken.payload.sub;
      const organizationName = idToken.payload["custom:organizationName"];


      setAuthState({
        jwt,
        organizationId,
        organizationName,
        isAuthenticated: true,
        isLoading: true,
        userId:userId
      });
    }).catch(error => {
      console.log('Error fetching user session:', error);
      setAuthState({
        jwt: null,
        organizationId: null,
        isAuthenticated: false,
        isLoading: false
      });
    }).finally(() => {
      setAuthState(prevState => ({
        ...prevState,
        isLoading: false, 
      }));
    });
  };
  

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
};
