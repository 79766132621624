import { Outlet } from "react-router";
import Header from "./header/Header";
import "./Page.scss";
import UserAccountButton from "./header/UserAccountButton";
import SideNavigation from "./SideNavigation";
import { useAuth } from "../context/AuthContext";

const Page = ({ children }) => {

  const {isAuthenticated} = useAuth();



  return(
    <div className={`page ${isAuthenticated && "with-sidebar"}`}>
      <Header dashboard={true} >

      </Header>
      {isAuthenticated && <SideNavigation />}
      <Outlet />
      {children}
    </div>
  )
}

export default Page;