// src/components/GuideOptionsDropdown.js
import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useExperiences } from "../context/ExperiencesContext";

const GuideOptionsDropdown = ({ value, onChange }) => {
  const { guides } = useExperiences();

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="guide-options-label">Select Guide</InputLabel>
      <Select
        labelId="guide-options-label"
        // Use value ?? "" so that if value is undefined, it falls back to ""
        value={value ?? ""}
        label="Select Guide"
        onChange={(e) => onChange(e.target.value)}
      >
        {guides &&
          guides.map((guide) => (
            <MenuItem
              key={guide?.sk.replace("GUIDE#", "") || guide.guideId}
              value={guide?.sk.replace("GUIDE#", "") || guide.guideId}
            >
              {guide.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default GuideOptionsDropdown;
