// export const environmentOptions = [
//   { id: "0892691b-b5ad-4061-b208-404271e426f2", name: "Human" },
//   { id: "7d3ca5ac-be03-459c-9165-0490d4eb7bf7", name: "Bone" },
//   { id: "bc933b92-b561-4c1c-9f32-68d28acb0914", name: "Capillary" },
//   { id: "8e5d5433-fac7-4c97-b9de-6b83ca89ec61", name: "BoneMarrow" },
//   { id: "9f69ad9d-c615-4a72-a585-2708c8830939", name: "Stem cell" },
//   { id: "58c8d006-98ff-4ad9-8882-a51f99fddbf6", name: "Nucleus" },
//   { id: "7d300abf-ca9b-4726-a74d-94434728a161", name: "RedBloodCell" },
// ];


// 1) Build an array of environment objects: { id, name }
export const environmentOptions = (system) => {
console.log("SYSTEM", system);
  if(system == undefined){
    return [];
  }
  
  else if (system){
    console.log("System environments", system.environments)
  return Object.entries(system.environments).map(([envId, envObj]) => ({

      id: envId,                      // The key
      name: envObj.environmentName,   // The environment's name property
 
  }));
} }

// 2) Build an array of element objects: { id, name }
//    We'll gather elements from *all* environments.
//    If you have a real "elementName" somewhere, replace "elementID" below.
export const elementOptions = ({system}) => 
  
  { 
    
     if (system == undefined) {
       return [];
     }
  
    
    return system.environments.flatMap(env => env.elements)
  .map(element => ({
    id: element.id,
    // If your data has a real name (e.g. element.elementData.name),
    // use that instead. Otherwise, use the ID as fallback:
    name: element.labelData.displayName
  }));
}